/*
 * @Author: dongjia
 * @Date: 2021-06-28 11:46:26
 * @LastEditTime: 2021-06-29 18:39:07
 * @LastEditors: aleaner
 * @Description: 商品列表页接口
 * @FilePath: \saas-admin-vue\src\modules\wx-store\api\product\list.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 商品列表数据
export const productList = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/index',
    method: 'post',
    data,
    notCancel: true
  });
};

// 更新显示状态
export const updateShow = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/changeShow',
    method: 'post',
    data
  });
};

// 更新推荐状态
export const updateRecommend = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/changeRecommend',
    method: 'post',
    data
  });
};

// 数据软删除
export const softDelete = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/softDelete',
    method: 'post',
    data
  });
};

// 数据真删除
export const Delete = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/delete',
    method: 'post',
    data
  });
};

// 数据还原
export const putBack = data => {
  return api({
    url: '/admin/wx_open/wx_store_goods/putBack',
    method: 'post',
    data
  });
};

// 获取所有商品分类
export const categoryArr = data => {
  return api({
    url: '/admin/wx_open/wx_store_category/all',
    method: 'post',
    data
  });
};

// 商品列表导出
export const document = data => {
  return download({
    url: "/admin/wx_open/wx_store_goods/document",
    method: "post",
    data,
    download: true
  });
};
