<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="商品编号：" prop="spu_id">
          <el-input v-model="form.spu_id" placeholder="请输入商品编号"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="商品分类：" prop="category_id">
          <el-select v-model="category_id" placeholder="请选择商品分类">
            <el-option v-for="(item, index) in categoryArr" :key="item.id" :value="item.id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="价格区间：" prop="">
          <div class="container">
            <el-form-item label-width="0px" prop="min_price">
              <el-input v-model.number="form.min_price"></el-input>
            </el-form-item>
            <div style="margin: 0 10px">-</div>
            <el-form-item label-width="0px" prop="max_price">
              <el-input v-model.number="form.max_price"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="修改时间：" start-prop="update_start_time"
          :start-time.sync="form.update_start_time" end-prop="update_end_time"
          :end-time.sync="form.update_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { categoryArr, document } from "../../api/product/list";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryArr: [],
    };
  },
  methods: {
    // 获取商品分类选项列表
    getCategoryArr() {
      categoryArr()
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    onExport() {
      const postData = { ...this.form, page: this.page };
      return document(postData).catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getCategoryArr();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    category_id: {
      get() {
        return this.form.category_id == -1 ? "" : this.form.category_id;
      },
      set(val) {
        this.form.category_id = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  ::v-deep.el-input__inner {
    width: 67px !important;
  }
}
</style>