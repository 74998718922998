<!--
 * @Author: dongjia
 * @Date: 2021-06-25 16:55:54
 * @LastEditTime: 2021-08-26 09:50:09
 * @LastEditors: aleaner
 * @Description: 微信小商店商品列表页
 * @FilePath: \saas-admin-vue\src\modules\wx-store\views\product-list\list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <el-button class="top-btn" type="primary" size="small" @click="handleAddGoods">新增
    </el-button>
    <list-filter :filterForm="filterForm" :page="pageData.current_page" :uploadFilter="ok" />
    <list-tabs v-model="filterForm.tab" :tabs="tabArr" :isLoading="loading" @tab-click="handleClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="['normal','recommend'].includes(filterForm.tab)">
        <!-- <el-button v-if="filterForm.tab === 'normal'" type="primary" size="small" @click="isRecommend">推荐
        </el-button> -->
        <el-button v-if="filterForm.tab === 'recommend'" type="primary" size="small" @click="notRecommend">
          取消推荐</el-button>
        <el-button type="danger" size="small" @click="handleDel">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button type="success" size="small" @click="handleReduct">还原</el-button>
        <el-button type="danger" size="small" @click="handleDel">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab == 'deleted'">
              <el-button size="small" @click="ReductRow(scope.row)" type="text">
                还原
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text">删除</el-button>
            </el-button-group>
            <el-button-group v-if="filterForm.tab == 'recommend'">
              <el-button size="small" @click="noRecommendRow(scope.row)" type="text">
                取消推荐
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text">删除</el-button>
            </el-button-group>
            <el-button-group v-if="filterForm.tab == 'normal'">
              <!-- <el-button size="small" @click="checkRow(scope.row)" type="text">
                查看
              </el-button> -->
              <el-button size="small" @click="editRow(scope.row)" type="text">
                编辑
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
            <template slot-scope="scope">
              <el-checkbox v-if="['is_show','is_recommend'].includes(th.prop)" v-model="scope.row[th.prop]"
                :true-label="1" :false-label="0" @change="changeStatus(scope.row, th.prop)">
              </el-checkbox>
              <!-- 商品图片 -->
              <el-image v-else-if="th.prop === 'image'" :src="fixImageUrl(scope.row[th.prop])"
                class="list-image cursor-pointer" @click="$previewImage([scope.row[th.prop]])" fit="cover">
              </el-image>
              <!-- 排序 -->
              <!-- <PopoverInputEdit v-else-if="th.prop === 'sort'" v-model="scope.row.sort" input-type="number"
                :validator="validator" @confirm="changeStatus(scope.row, th.prop)">
              </PopoverInputEdit> -->
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import listFilter from "../../components/ProductList/ListFilter";
// import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import {
  productList,
  // updateSort,
  updateShow,
  updateRecommend,
  softDelete,
  Delete,
  putBack,
} from "../../api/product/list";
export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        // { label: "推荐中", name: "recommend" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: [
        { label: "商品编号", prop: "spu_id", minWidth: 80 },
        { label: "商品图片", prop: "image", minWidth: 100 },
        {
          label: "商品名称",
          prop: "name",
          minWidth: 110,
        },
        {
          label: "商品分类",
          prop: "category_name",
          minWidth: 100,
        },
        {
          label: "价格",
          prop: "price",
          minWidth: 100,
          sortable: true,
        },
        {
          label: "修改时间",
          prop: "update_time",
          minWidth: 160,
          sortable: true,
        },
        // { label: "排序", prop: "sort" },
        // { label: "推荐", prop: "is_recommend" },
        // { label: "显示", prop: "is_show" },
      ],
      //筛选对象
      filterForm: {
        update_start_time: -1, //开始时间
        update_end_time: -1, //结束时间
        spu_id: "", //商品编号
        name: "", // 商品名称
        min_price: "", // 最低价格
        max_price: "", // 最高价格
        category_id: -1, //分类id
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      // 筛选缓存
      filters: {
        normal: null,
        recommend: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
        tab: "normal",
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      selectData: {
        id: 0, // 品牌Id
        mark: 0, // 添加或删除
        status: 0, //添加或删除的类型
      },
      /* 行内编辑数字验证 */
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      // pid_list: [], //上级组织列表,
      openJoin: false, //加入组织弹窗
      openHandleList: false, //列表操作弹窗
    };
  },
  methods: {
    // 获取列表数据
    getProductList(pageData) {
      // this.tbody = [];
      this.pageData = {};
      this.loading = true;
      productList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取上级组织列表
    // getPidList() {
    //   const data = {};
    //   data.is_show_no_parent = data.is_show_all_brand = 1;
    //   PidList(data)
    //     .then((res) => {
    //       this.pid_list = res.data;
    //     })
    //     .catch((err) => {});
    // },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 新增品牌
    handleAddGoods() {
      this.$router.push({
        name: "WxStoreGoodsDetail",
        params: { id: 0, type: "add" },
      });
    },
    // 新增活动文章
    handleAdd(row, key) {
      this.selectData.id = row.id;
      this.selectData.mark = 0;
      this.selectData.status = key === "articles_count" ? 1 : 0;
      this.openHandleList = true;
    },
    // 减少活动文章
    handleReduce(row, key) {
      this.selectData.id = row.id;
      this.selectData.mark = 1;
      this.selectData.status = key === "articles_count" ? 1 : 0;
      this.openHandleList = true;
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (["normal", "recommend"].includes(this.filterForm.tab)) {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (["normal", "recommend"].includes(this.filterForm.tab)) {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (["normal", "recommend"].includes(this.filterForm.tab)) {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量推荐
    isRecommend() {
      if (this.selectArr.length) {
        const data = {
          id: this.selectArr,
          is_recommend: 1,
        };
        this.loading = true;
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 批量取消推荐
    notRecommend() {
      if (this.selectArr.length) {
        const data = {
          id: this.selectArr,
          is_recommend: 0,
        };
        this.loading = true;
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表取消推荐
    noRecommendRow(row) {
      const data = {
        id: row.id,
        is_recommend: 0,
      };
      this.loading = true;
      // 更新推荐状态
      updateRecommend(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch(() => {
          this.refreshList();
        });
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "WxStoreGoodsDetail",
        params: { id: row.id, type: "edit" },
      });
    },
    // 点击查看
    checkRow(row) {
      this.$router.push({ name: "checkBrand", params: { id: row.id } });
    },
    // 切换tab栏
    handleClick(e) {
      console.log(e.name);
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          update_start_time: -1, //开始时间
          update_end_time: -1, //结束时间
          spu_id: "", //商品编号
          name: "", // 商品名称
          min_price: "", // 最低价格
          max_price: "", // 最高价格
          category_id: -1, //分类id
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getProductList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getProductList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getProductList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getProductList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      this.loading = true;
      if (key == "is_recommend") {
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
      //  else {
      //   // 更新排序状态
      //   updateSort(data)
      //     .then((res) => {
      //       this.$message.success(res.msg);
      //       this.refreshList();
      //     })
      //     .catch(() => {
      //       this.refreshList();
      //     });
      // }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getProductList(pageData);
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    this.getProductList(this.pageData); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    // PopoverInputEdit,
  },
};
</script>

<style lang="scss" scoped>
.list-image {
  width: 60px;
  height: 60px;
}
</style>